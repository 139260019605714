import React from "react"
import styled from "styled-components"

const Footer = () => (
  <Container>
    <Group>
      <UpperSection>
        <BrandLogo />
        <Center>
          <a
            href="https://www.instagram.com/choosifyapp"
            target="_blank"
            rel="nofollow"
          >
            <Section>BLOG</Section>
          </a>
          <a
            href="https://choosify.es/#/conditions"
            target="_blank"
            rel="nofollow"
          >
            <Section>TÉRMINOS</Section>
          </a>
          <a
            href="https://choosify.es/#/privacy"
            target="_blank"
            rel="nofollow"
          >
            <Section>PRIVACIDAD</Section>
          </a>
        </Center>
        <Right>
          <a
            href="https://www.instagram.com/choosifyapp"
            target="_blank"
            rel="nofollow"
          >
            <Instagram />
          </a>
          <a
            href="https://www.facebook.com/Cravy-1892042460880645/"
            target="_blank"
            rel="nofollow"
          >
            <Facebook />
          </a>
          <a
            href="https://twitter.com/choosify_app"
            target="_blank"
            rel="nofollow"
          >
            <Twitter />
          </a>
          {/*         
           <a
            href="https://www.linkedin.com/company/cravy-app/"
            target="_blank"
            rel="nofollow"
          >
            <Linkedin />
          </a>*/}
        </Right>
      </UpperSection>
      <BottomSection>
        <CopyrightSection>
          <Copyright>
            Avinguda Segle XXI S/N 08840 Viladecans, Barcelona
            <br />© 2019 Choosify. Todos los derechos reservados.
          </Copyright>
        </CopyrightSection>
      </BottomSection>
    </Group>
  </Container>
)

export default Footer

const Container = styled.div`
  width: 100%;
`

const Group = styled.div`
  padding: 60px;
  padding-top: 90px;
`
const UpperSection = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;

  @media (max-width: 776px) {
    grid-template-columns: repeat(1, auto);
    grid-row-gap: 20px;
  }
`
const BrandLogo = styled.div`
  background: url(${require("../images/brand-logo.png")});
  background-size: cover;
  width: 200px;
  height: 130px;
  justify-self: flex-start;
  @media (max-width: 776px) {
    justify-self: center;
  }
`
const Center = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 40px;
  align-items: center;
  justify-items: center;
  justify-self: center;

  @media (max-width: 380px) {
    grid-template-columns: repeat(1, auto);
    grid-row-gap: 10px;
  }
`
const Section = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #b3b3b3;
  margin: 0px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #01cc9a;
  }
`
const Right = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 10px;
  align-items: center;
  justify-items: center;
  justify-self: flex-end;

  @media (max-width: 776px) {
    justify-self: center;
  }
`
const Instagram = styled.div`
  width: 24px;
  height: 24px;
  background: url(${require("../images/footer/instagram.png")});
  background-size: cover;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: url(${require("../images/footer/instagram-green.png")});
    background-size: contain;
  }
`
const Facebook = styled.div`
  width: 24px;
  height: 24px;
  background: url(${require("../images/footer/facebook.png")});
  background-size: contain;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: url(${require("../images/footer/facebook-green.png")});
    background-size: contain;
  }
`
const Twitter = styled.div`
  width: 24px;
  height: 24px;
  background: url(${require("../images/footer/twitter.png")});
  background-size: contain;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: url(${require("../images/footer/twitter-green.png")});
    background-size: contain;
  }
`
const Linkedin = styled.div`
  width: 24px;
  height: 24px;
  background: url(${require("../images/footer/linkedin.png")});
  background-size: contain;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: url(${require("../images/footer/linkedin-green.png")});
    background-size: contain;
  }
`

const BottomSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const CopyrightSection = styled.div`
  display: flex;
  text-align: center;
`

const Copyright = styled.p`
  color: #b3b3b3;
  font-size: 12px;
`
