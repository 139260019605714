import { keyframes } from "styled-components"

export const appear = keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: 1;
}
`

export const slideIn = keyframes`
0% {
  transform: translateX(-2%);
}

100% {
  transform: translateX(0);
}
`

export const shrink = keyframes`
0% {
  width: 95%;
}

100% {
  width: 90%;
}
`
