import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import MenuButton from "./MenuButton"
import Menu from "./Menu"
import MenuItem from "./MenuItem"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
  }

  const handleLinkClick = () => {
    setMenuOpen(false)
  }

  const menu = [
    { name: "APLICACIÓN", url: "/" },
    { name: "NEGOCIOS", url: "negocios" },
  ]

  const menuItems = menu.map((menuItem, index) => {
    return (
      <Link to={menuItem.url}>
        <MenuItem
          key={index}
          delay={`${index * 0.1}s`}
          onClick={() => {
            handleLinkClick()
          }}
        >
          {menuItem.name}
        </MenuItem>
      </Link>
    )
  })

  return (
    <Container>
      <HeaderGroup>
        <Link to="/">
          <Logo />
        </Link>
        <Link to="/" style={{ justifySelf: "flex-end" }}>
          <Section>APLICACIÓN</Section>
        </Link>
        <Link to="/negocios" style={{ justifySelf: "flex-end" }}>
          <Section>NEGOCIOS</Section>
  </Link>
        <MenuWrapper>
          <MenuButton open={menuOpen} onClick={() => handleMenuClick()} />
  </MenuWrapper>
      </HeaderGroup>
      <Menu open={menuOpen}>{menuItems}</Menu>
    </Container>
  )
}

export default Header

const Container = styled.div`
  width: 100%;
  z-index: 50;
`
const HeaderGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  max-width: 468px;
  padding: 30px 60px 30px 60px;
  align-items: center;

  @media (max-width: 776px) {
    padding: 30px 30px 30px 30px;
    max-width: 100%;
    grid-template-columns: repeat(2, auto);
  }
`
const Logo = styled.div`
  background: url(${require("../images/logo_negro.svg")});
  background-size: cover;
  width: 160px;
  height: 30px;

  @media (max-width: 776px) {
    width: 160px;
    height: 30px;
  }
`

const Section = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #b3b3b3;
  margin: 0px;
  justify-self: flex-end;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #01cc9a;
  }

  @media (max-width: 776px) {
    display: none;
  }
`

const MenuWrapper = styled.div`
  display: none;

  @media (max-width: 776px) {
    display: flex;
    justify-self: flex-end;
  }
`
